import React, { useState, useContext } from "react";
import * as Yup from "yup";
import "./style.css";
import { navigate } from "gatsby";
import { Formik, Field, Form } from "formik";
import { useMutation } from "@apollo/react-hooks";
import { validateToken } from "../graphql/mutations";
import { toast } from "react-toastify";
import { LanguageContext } from "../../../lang";

const ValidateEmailVerificationCode = Yup.object().shape({
  verificationCode: Yup.string()
    .min(6, "Code Must be 6 Digit Number")
    .max(6, "Code Cannot be greater than 6 Digit Number")
    .required("Please 6 digit Code"),
});

const intialValue = {
  verificationCode: "",
};

const ConfirmVerificationCode = ({ state }) => {
  const [validateTokenTrigger, { loading, data, error }] = useMutation(
    validateToken
  );
  const [token, setToken] = useState("");
  const { message, email } = state ? state : {};
  const handelVerificationCode = (values) => {
    setToken(values.verificationCode);
    validateTokenTrigger({
      variables: {
        data: {
          email,
          token: values.verificationCode,
        },
      },
    });
  };
  if (data) {
    toast.success("Token Validated Successfully", {
      autoClose: 1200,
    });
    setTimeout(() => {
      navigate("/reset-password", {
        state: {
          email,
          token,
        },
      });
    }, 1200);
  }
  if (error) {
    toast.error(error.graphQLErrors[0].message.message, {
      autoClose: 1200,
    });
  }

  const {
    translations: { login },
  } = useContext(LanguageContext);

  const { emailVerifyHeading, emailVerifyBtn } = login;

  return (
    <div className="container-fluid d-flex hm-login-bg justify-content-center align-items-center">
      <div id="forgotPassword">
        <div className="forgotPasswordTextView">
          <p>{emailVerifyHeading}</p>
        </div>
        <div className="instructionText">
          <p>{message}</p>
        </div>
        <Formik
          initialValues={intialValue}
          validationSchema={ValidateEmailVerificationCode}
          onSubmit={(values) => handelVerificationCode(values)}
        >
          {({ values, errors, touched, handleSubmit }) => (
            <div className="inputstyleview">
              <Form className="formStyle">
                <Field
                  className="emailStyle"
                  type="text"
                  name="verificationCode"
                  placeholder="Enter 6 Digit Code"
                />
                {errors.verificationCode && touched.verificationCode ? (
                  <div className="bg-danger my-1 p-0 col-12 rounded">
                    <p className="text-white p-1 m-0">
                      {errors.verificationCode}
                    </p>
                  </div>
                ) : null}
              </Form>
              <div onClick={handleSubmit} className="loginButtonStyle">
                <p className="loginButtonText">{emailVerifyBtn}</p>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export { ConfirmVerificationCode };
