import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import { ConfirmVerificationCode } from '../components/ForgotPassword/ConfirmVerificationCode'
const Login = ({ location }) => {
	const { state = {} } = location

	return (
		<Layout>
			<SEO title='Verify Email Code' />
			<ConfirmVerificationCode state={state} />
		</Layout>
	)
}

export default Login
